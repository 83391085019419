<template>
    <header class="container-fluid fixed-top shadow-sm0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="nav-item-header">
                        <div class="nav-item-header-link" v-for="(link, i) in menu" :key="i">
                            <router-link tag="a" :exact="link.active" active-class="active"
								:to="{ name: link.route }" v-slot="{ isActive, isExactActive }">
								{{ link.title }}
							</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    data: () => ({
        menu: [
            {
                title: 'Оператор',
                route: 'home',
                active: true
            },
            {
                title: 'Журнал',
                route: 'journal',
                active: false
            },
            {
                title: 'Склад',
                route: 'stock',
                active: false
            },
            {
                title: 'Продажи',
                route: 'sales',
                active: false
            },
            {
                title: 'Товары',
                route: 'goods',
                active: false
            }
        ]
    })
}
</script>