<template>
	<Navbar />
	<router-view class="content-view" />
</template>

<script>
import Navbar from '@/components/Navbar.vue';

export default {
	components: {
		Navbar
	},

	data: () => ({
		
	}),

	methods: {
		
	},

	mounted() {
		
	}
}
</script>