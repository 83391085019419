import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/style.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'

createApp(App).
    use(router).
    use(bootstrap).
    mount('#app')